<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <!-- 祝福语 -->
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI祝福语" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">主题描述：</p>
                            <div class="upload_box">
                                <el-input v-model="form.keyword" placeholder="如：节日、主题（升迁、结婚等）"></el-input>
                            </div>
                        </div>
                        <button class="create_but" @click="FileData">生成内容</button>
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />

    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_title from '@/components/ai_title.vue'
import ai_text_content from '@/components/ai_text_content.vue'
export default {
    name: '',
    mounted() { this.getUserInfo() },

    components: {
        wordNav,
        ai_title,
        ai_text_content
    },
    data() {
        return {
            form: {
                keyword: '', // 关键词
            },
            // user_info:{},
            selectList: ['祝福语',],
            fullscreenLoading: false,
            contentData: {}, //内容
            aiNumShow: false,
            aiPoupShow: false,
        }
    },
    methods: {
        // 获取用户信息
        getUserInfo() {
            this.curlGet('/api/user/info').then((res) => {
                if (res.data.code) {
                    this.$user_info = res.data.data
                    this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                }
            })
        },
        copy() {
            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(this.contentData.output_content).then(
                (e) => {

                    return this.$message({
                        message: '复制成功',
                        type: 'success'
                    });
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning'
                    });
                }
            );
        },
        FileData() {

            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }
        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true
            this.curlPost('/file/blessings_create', {
                keyword: this.form.keyword,
                mobile: this.$user_info.mobile,

            }).then(res => {
                console.log(res);
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        console.log(res);
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>